import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { tokenizeObject } from "../../utils/format"
import SeoSchema from "../seo/seoSchema.component"

export default function FaqSeoSchema ({ questions }) {
  const seoItems = useMemo(() => questions.map(question => tokenizeObject(APP_CONFIG.seo.schema.faqItem, question)), [
    questions,
  ])

  return <SeoSchema name="faq" items={seoItems} />
}

FaqSeoSchema.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired,
    }).isRequired,
  ),
}
