import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Arrow } from "../structure/theme/arrow.component"
import { addEventTracking } from "../thirdParties/gtm/tagManager"

const Item = styled.li`
  list-style-type: none;
  padding: 5px;
`

const Question = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.grey.g300};
  color: ${props => props.isOpened && props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
`

const AnswerDiv = styled.div`
  padding-top: 1px;
`

export default function FaqQuestionAnswer ({ item, isOpen, open, reference, styles }) {
  return (
    <Item key={item.id} data-testid="faq_item">
      <Question
        onClick={() => {
          open(item)
          addEventTracking(`eventGA`, {
            eventCategory: `FAQ`,
            eventAction: `clic`,
            eventLabel: item.question,
          })
        }}
        isOpened={isOpen}
        data-testid="faq_collapse"
      >
        {item.question}
        <Arrow isOpened={isOpen} />
      </Question>
      <div ref={reference} style={styles} data-testid="faq_collapsible">
        {React.isValidElement(item.answer) ? (
          <AnswerDiv>{item.answer}</AnswerDiv>
        ) : (
          <AnswerDiv dangerouslySetInnerHTML={{ __html: item.answer }} />
        )}
      </div>
    </Item>
  )
}

FaqQuestionAnswer.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.node.isRequired.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  reference: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
}
