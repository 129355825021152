import React from "react"
import PropTypes from "prop-types"
import { orderQuestionByPosition } from "./faq.utils"
import FaqCollapseRenderer from "./faqCollapseRenderer.component"
import Collapse from "../../components/collapse/collapseMultiple.component"
import FaqSeoSchema from "./faqSeoSchema.component"
import styled from "styled-components"

const Container = styled.div`
  max-width: 100%;
`

export default function Faq ({ questions = [], double = false, withSeo = true }) {
  return (
    <Container id="faq">
      {withSeo && <FaqSeoSchema questions={questions} />}
      <Collapse
        items={questions.slice(0).sort(orderQuestionByPosition)}
        itemKey="id"
        allowClose
        double={double}
        Renderer={FaqCollapseRenderer}
      />
    </Container>
  )
}

Faq.propTypes = {
  double: PropTypes.bool,
  withSeo: PropTypes.bool,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired,
      position: PropTypes.number.isRequired,
    }).isRequired,
  ),
}
