import React, { useState, memo } from "react"
import PropTypes from "prop-types"
import CollapseSimple from "./collapse.component"

function Collapse ({
  items = [],
  children,
  itemKey = `id`,
  allowClose = false,
  Renderer = null,
  defaultItem = ``,
  ...props
}) {
  const [selected, setSelected] = useState(defaultItem)

  function open (item) {
    setSelected(!item[itemKey] || (item[itemKey] === selected && allowClose) ? `` : item[itemKey])
  }

  if (Renderer) {
    return <Renderer items={items} itemKey={itemKey} open={open} selected={selected} {...props} />
  }

  return items.map(item => (
    <CollapseSimple key={item[itemKey]} open={selected === item[itemKey]}>
      {({ isOpen, ref, style }) => {
        return children({
          item,
          ref: ref,
          style: style,
          toggle: open,
          isOpen: isOpen,
        })
      }}
    </CollapseSimple>
  ))
}

Collapse.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.func,
  itemKey: PropTypes.string,
  allowClose: PropTypes.bool,
  customRender: PropTypes.func,
  defaultItem: PropTypes.number,
}

export default memo(Collapse)
