import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import FaqColumn from "./faqColumn.component"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default function FaqCollapseRenderer ({ items, open, selected, itemKey, double }) {
  if (items.length < 2 || !double) {
    return <FaqColumn items={items} open={open} itemKey={itemKey} selected={selected} />
  }
  const half = Math.floor(items.length / 2) + (items.length % 2)
  const part1 = items.slice(0, half)
  const part2 = items.slice(half, items.length)

  return (
    <Container>
      <FaqColumn items={part1} open={open} itemKey={itemKey} selected={selected} half />
      <FaqColumn items={part2} open={open} itemKey={itemKey} selected={selected} half />
    </Container>
  )
}

FaqCollapseRenderer.propTypes = {
  items: PropTypes.array.isRequired,
  open: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  double: PropTypes.bool.isRequired,
}
