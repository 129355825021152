export function orderQuestionByPosition (q1, q2) {
  if (q1.position > q2.position) {
    return 1
  }
  if (q1.position < q2.position) {
    return -1
  }

  return 0
}
