import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import CollapseSimple from "../../components/collapse/collapse.component"
import FaqQuestionAnswer from "./faqQuestionAnswer.component"

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
`

const Container = styled.div`
  width: 100%;

  ${breakpoint(`medium`)`
    width: ${props => (props.half ? 50 : 100)}%;
  `}
`

export default function FaqColumn ({ items, selected, itemKey, open, half = false }) {
  return (
    <Container half={half}>
      <List>
        {items.map(item => (
          <CollapseSimple key={item[itemKey]} open={selected === item[itemKey]}>
            {({ isOpen, ref, style }) => (
              <FaqQuestionAnswer
                item={item}
                key={item[itemKey]}
                reference={ref}
                styles={style}
                open={open}
                isOpen={isOpen}
              />
            )}
          </CollapseSimple>
        ))}
      </List>
    </Container>
  )
}

FaqColumn.propTypes = {
  items: PropTypes.array.isRequired,
  open: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  half: PropTypes.bool,
}
